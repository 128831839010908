/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddClientCreditGroup()"
          >Agregar agrupación de crédito de clientes</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de agrupaciones: <b>{{ clientsCreditGroups.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="clientsCreditGroups"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="ClientsCreditGroupList"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.DESCRIPCION}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="DESCRIPCION"
      >
        <template #empty>
          <div class="has-text-centered">No hay agrupaciones</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <p v-if="column.money && checkIfValid(props.row[column.field])">
              {{ props.row[column.field] | money("MXN", 2) }}
            </p>
            <p v-if="!column.money && checkIfValid(props.row[column.field])">
              {{ props.row[column.field] }}
            </p>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddClientCreditGroup
                  :clientCreditGroupInformation="props.row"
                />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddClientCreditGroup from "./AddClientCreditGroup.vue";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ClientsCreditGroupList",
  components: {
    AddClientCreditGroup,
  },
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "DESCRIPCION",
            label: "Descripción",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "creditLitmit",
            label: "Limite de crédito",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
          {
            field: "creditUsed",
            label: "Limite de crédito usado",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
          {
            field: "remainingBalance",
            label: "Limite de crédito restante",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
        ],
        "clients-credit-group-list-columns"
      ),
      masks: Masks,
      refreshLoading: false,
    };
  },
  async created() {},
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCLIENTCREDITGROUP"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    openAddClientCreditGroup() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddClientCreditGroup,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "clients-credit-group-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    clientsCreditGroups() {
      return this.$store.getters.CLIENTSCREDITGROUPS.map(
        (singleClientCreditGroup) => {
          const clientCreditGroupsWithProps = { ...singleClientCreditGroup };

          let creditLitmit = 0;
          let creditUsed = 0;
          let remainingBalance = 0;

          for (const singleClient of clientCreditGroupsWithProps.CLAVE_CLIENTES) {
            creditLitmit += singleClient.LIMITE_CRED;
            creditUsed += singleClient.BALANCE_CRED;
            remainingBalance +=
              singleClient.LIMITE_CRED - singleClient.BALANCE_CRED;
          }

          return {
            ...clientCreditGroupsWithProps,
            creditLitmit,
            creditUsed,
            remainingBalance,
          };
        }
      );
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        if (clientWithProps.LISTA_PRECIOS_PRE) {
          clientWithProps.defaultPriceList =
            clientWithProps.LISTA_PRECIOS_PRE.CLAVE_LISTAP;
        }
        return clientWithProps;
      }).filter((singleClient) => {
        return singleClient.TIPO === "CL";
      });
    },
    regimenes() {
      return this.$store.getters.SATREGIMENES.map((singleRegimen) => {
        const regimenWithProps = { ...singleRegimen };
        if (regimenWithProps.TIPO_R === "A") {
          regimenWithProps.typeFormatted = "Ambos";
        } else if (regimenWithProps.TIPO_R === "F") {
          regimenWithProps.typeFormatted = "Persona física";
        } else if (regimenWithProps.TIPO_R === "M") {
          regimenWithProps.typeFormatted = "Persona moral";
        }
        return regimenWithProps;
      });
    },
    cfdiUses() {
      return this.$store.getters.SATCFDIUSES.map((singleUse) => {
        const useWithProps = { ...singleUse };
        if (useWithProps.TIPO_P === "A") {
          useWithProps.typeFormatted = "Ambos";
        } else if (useWithProps.TIPO_P === "F") {
          useWithProps.typeFormatted = "Persona física";
        } else if (useWithProps.TIPO_P === "M") {
          useWithProps.typeFormatted = "Persona moral";
        }
        return useWithProps;
      });
    },
    commercialBusiness() {
      return this.$store.getters.COMMERCIALBUSINESS.map(
        (singleCommercialBusiness) => {
          const commercialBusinessWithProps = { ...singleCommercialBusiness };
          if (commercialBusinessWithProps.TIPO === "C") {
            commercialBusinessWithProps.typeFormatted = "Clientes";
          } else if (commercialBusinessWithProps.TIPO === "P") {
            commercialBusinessWithProps.typeFormatted = "Proveedores";
          }
          return commercialBusinessWithProps;
        }
      ).filter((singleCommercialBusiness) => {
        return singleCommercialBusiness.TIPO === "C";
      });
    },
    warehouses() {
      return this.$store.getters.COMMERCIALBUSINESS.map(
        (singleCommercialBusiness) => {
          const commercialBusinessWithProps = { ...singleCommercialBusiness };
          if (commercialBusinessWithProps.TIPO === "C") {
            commercialBusinessWithProps.typeFormatted = "Clientes";
          } else if (commercialBusinessWithProps.TIPO === "P") {
            commercialBusinessWithProps.typeFormatted = "Proveedores";
          }
          return commercialBusinessWithProps;
        }
      ).filter((singleCommercialBusiness) => {
        return singleCommercialBusiness.TIPO === "C";
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
