/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddUnlockClient()"
          >Agregar desbloqueo de cliente</b-button
        >
      </div>

      <div class="control">
        <b-button type="is-primary" @click="confirmDeleteClientsUnlocks()"
          >Borrar desbloqueos de clientes</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de debloqueos de clientes: <b>{{ clientsUnlocks.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="clientsUnlocks"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="ClientsUnlocks"
        :checked-rows.sync="checkedClientsUnlocked"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CODIGO}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="FECHA_FOR"
        checkable
      >
        <template #empty>
          <div class="has-text-centered">No hay desbloqueos de clientes</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <p v-if="column.money && checkIfValid(props.row[column.field])">
              {{ props.row[column.field] | money("MXN", 2) }}
            </p>
            <p v-if="!column.money && checkIfValid(props.row[column.field])">
              {{ props.row[column.field] }}
            </p>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddClientUnlockModal :clientUnlockInformation="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddClientUnlockModal from "./AddClientUnlockModal";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ClientsUnlocksList",
  components: { AddClientUnlockModal },
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedClientsUnlocked: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "CODIGO",
            label: "Codigo",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "CLIENTE_FOR",
            label: "Cliente",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "FECHA_FOR",
            label: "Fecha",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
          {
            field: "STATUS_FOR",
            label: "Status",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "CANTIDAD",
            label: "Cantidad",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
          {
            field: "FECHA_FIN_FOR",
            label: "Fecha de caducidad",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDateEndDate,
          },
          {
            field: "DESCRIPCION",
            label: "Descripción",
            sortable: true,
            searchable: true,
            display: true,
          },
        ],
        "clients-unlocks-list-columns"
      ),
      masks: Masks,
    };
  },
  async created() {},
  methods: {
    async reloadInformation() {
      let allPromises = [];
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      await Promise.all(allPromises);
    },
    confirmDeleteClientsUnlocks() {
      if (this.checkedClientsUnlocked.length > 0) {
        this.$store
          .dispatch("DELETECLIENTSUNLOCKS", {
            deleteClientsUnlocks: this.checkedClientsUnlocked,
          })
          .then((response) => {
            if (response == "Success") {
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message:
                  "Se borrarón exitosamente los desbloqueos de clientes seleccionados",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al borrar los desbloqueos de clientes en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay debloqueos de clientes seleccionados",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openAddUnlockClient() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddClientUnlockModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDateEndDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_FIN).getTime() || -Infinity) -
          (new Date(a.FECHA_FIN).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_FIN).getTime() || -Infinity) -
          (new Date(b.FECHA_FIN).getTime() || -Infinity)
        );
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "clients-unlocks-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        if (clientWithProps.LISTA_PRECIOS_PRE) {
          clientWithProps.defaultPriceList =
            clientWithProps.LISTA_PRECIOS_PRE.CLAVE_LISTAP;
        }
        return clientWithProps;
      }).filter((singleClient) => {
        return singleClient.TIPO === "CL";
      });
    },
    clientsUnlocks() {
      return this.$store.getters.CLIENTSUNLOCKS.map((singleClientUnlock) => {
        const singleClientUnlockWithProps = { ...singleClientUnlock };

        singleClientUnlockWithProps.FECHA_FOR = moment(
          singleClientUnlock.FECHA
        ).format("DD-MM-YYYY HH:mm");

        singleClientUnlockWithProps.FECHA_FIN_FOR = moment(
          singleClientUnlock.FECHA_FIN
        ).format("DD-MM-YYYY HH:mm");

        if (singleClientUnlock.STATUS === "AC") {
          singleClientUnlockWithProps.STATUS_FOR = "Activado";
        }

        if (singleClientUnlock.STATUS === "DE") {
          singleClientUnlockWithProps.STATUS_FOR = "Desactivado";
        }

        singleClientUnlockWithProps.CLIENTE_FOR = singleClientUnlock.CLIENTE
          ? singleClientUnlock.CLIENTE.NOMBRE_EMPRESA
          : "";

        return singleClientUnlockWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
